import { useContext, useEffect } from "react";
import { createCtx } from "lib/context";

export const getLocation = async () => {
  try {
    const res = await fetch("https://extreme-ip-lookup.com/json/");
    const data = await res.json();
    return data?.country;
  } catch (e) {
    return;
  }
};

const [locationCtx, locationProvider] = createCtx<string | null>(null);

export const LocationContext = locationCtx;
export const LocationProvider = locationProvider;

export const useLocation = () => {
  const { state, update } = useContext(LocationContext);
  useEffect(() => {
    getLocation().then((country) => update(country || undefined));
  }, []);
  return state;
};

export const useGetLocation = () => useContext(LocationContext).state;
