export default {
  title: "Daisho | Become a Data Science Super Hero",
  description:
    "Daisho enables No-Code data science for business users and business problems. Users can spend time on actions rather than coding and math.",
  canonical: "https://www.daisho.ml/",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://www.daisho.ml/",
    site_name: "Daisho",
  },
};
