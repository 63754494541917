import {
  signInWithEmailAndPassword,
  getAdditionalUserInfo,
} from "firebase/auth";
import {
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import { auth } from "./firebase";

export const emailAuth = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  try {
    await setPersistence(
      auth,
      rememberMe ? browserLocalPersistence : browserSessionPersistence
    );
    await signInWithEmailAndPassword(auth, email, password);
    return { success: true };
  } catch (error) {
    const { code, message } = error;
    switch (code) {
      case "auth/email-already-in-use":
        return {
          success: false,
          code,
          message: "This email is already in use",
        };
      case "auth/user-not-found":
        return { success: false, code, message: "Invalid Email Address" };
      case "auth/invalid-email":
        return { success: false, code, message: "Invalid Email Address" };
      case "auth/wrong-password":
        return {
          success: false,
          code,
          message: "Wrong Password or you had registered via Google.",
        };
      default:
        return { success: false, code, message };
    }
  }
};

export default emailAuth;
