import "react-responsive-carousel/lib/styles/carousel.min.css";
import "styles/globals.css";
import AppShell from "elements/App-Shell";

function MyApp({ Component, pageProps }) {
  return (
    <AppShell>
      <Component {...pageProps} />
    </AppShell>
  );
}

export default MyApp;
