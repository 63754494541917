import Link from "next/link";

const FooterLink = (props: {
  href: string;
  label: React.ReactNode;
  naked?: boolean;
  classes?: string;
}) =>
  props.naked ? (
    <Link href={props.href}>{props.label}</Link>
  ) : (
    <Link href={props.href} passHref>
      <a
        className={
          props.classes ||
          "text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
        }
        style={{ outline: "none" }}
      >
        {props.label}
      </a>
    </Link>
  );

export default FooterLink;
