import { User } from "firebase/auth";

export const callNewUserApiHook = async (
  email: string,
  method: string,
  currency?: string,
  userName?: string | null
) => {
  // Send alert to contact@daisho
  fetch("/api/new-user", {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    body: JSON.stringify({ email, method, userName }),
  });

  // Create customer + trial on Stripe
  // fetch("/api/init-customer", {
  //   method: "POST",
  //   headers: new Headers({ "Content-Type": "application/json" }),
  //   body: JSON.stringify({ email, currency }),
  // });
};

export const sendAlertsOnNewSignup = (user: User, currency?: string) => {
  const now = Date.now().valueOf();
  const created = new Date(user.metadata.creationTime).valueOf();
  const lastLogin = new Date(user.metadata.lastSignInTime).valueOf();

  // Both times are milliseconds.
  // Its a new user if last login and created are within a few seconds of each other
  // And its been no more than 2 minutes since the login was created
  const isNewUser =
    Math.abs(lastLogin - created) <= 1000 * 3 &&
    Math.abs(now - created) <= 1000 * 60 * 5;
  if (isNewUser) {
    const provider = user.providerData[0].providerId;
    callNewUserApiHook(user.email, provider, currency, user.displayName);
  }
};
