import Link from "next/link";
import Image from "next/image";

import FooterLink from "./Link";
import useCases from "./use-cases.preval";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-8 py-2 md:py-4 border-t border-gray-200">
          {/* 1st block */}
          <div className="sm:col-span-12 lg:col-span-3 hidden md:block">
            <div className="mb-2">
              {/* Logo */}
              <Link href="/" passHref>
                <a className="inline-block" aria-label="Daisho">
                  <Image
                    src={"/daisho-purple-logo.png"}
                    alt="Daisho"
                    className="h-12 md:h-20"
                    width={150}
                    height={60}
                  />
                </a>
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Product</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <FooterLink href="/philosophy" label="The Daisho Philosophy" />
              </li>
              <li className="mb-2">
                <FooterLink
                  href="/technology"
                  label="Technology behind Daisho"
                />
              </li>
              <li className="mb-2">
                <FooterLink href="/vs-auto-ml" label="Daisho vs AutoML" />
              </li>
              <li className="mb-2">
                <FooterLink href="/pricing" label="Pricing" />
              </li>
              <li className="mb-2">
                <FooterLink href="/faq" label="FAQ" />
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Use Cases</h6>
            <ul className="text-sm">
              {useCases.useCases.map((uc) => (
                <li key={uc.id} className="mb-2">
                  <FooterLink
                    href={`/use-cases/${uc.uid}`}
                    label={uc.data.title[0].text}
                  />
                </li>
              ))}
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-3">
            <h6 className="text-gray-800 font-medium mb-2">Company</h6>
            <ul className="text-sm">
              <li className="mb-2">
                <FooterLink href="/about-us" label="Our Story" />
              </li>
              <li className="mb-2">
                <FooterLink href="/blog" label="Blog" />
              </li>
              <li className="mb-2">
                <FooterLink href="/contact" label="Contact Us" />
              </li>
              <li className="mb-2">
                <FooterLink href="/terms" label="Terms" />
              </li>

              <li className="mb-2">
                <FooterLink href="/privacy" label="Privacy Policy" />
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom area */}

        <div className="py-4 md:py-8 border-t border-gray-200">
          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4 text-center">
            <p>
              Made with <span style={{ color: "#e25555" }}>&#9829;</span> in
              India.
            </p>
            <p>
              Copyright ©{" "}
              <a className="text-blue-600 hover:underline" href="/">
                Daisho
              </a>{" "}
              {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
          {/* Social links */}
          {/* <div className="md:flex md:items-center md:justify-center">
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li>
                <Link href="#">
                  <a
                    className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Twitter"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                    </svg>
                  </a>
                </Link>
              </li>
              <li className="ml-4">
                <Link href="#">
                  <a
                    className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Github"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                    </svg>
                  </a>
                </Link>
              </li>
              <li className="ml-4">
                <Link href="#">
                  <a
                    className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                    aria-label="Facebook"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                    </svg>
                  </a>
                </Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
