import { useContext, useCallback, useState, useEffect } from "react";

import { User } from "@firebase/auth";

import { useGetLocation } from "lib/location";
import { LoginFlagContext, UserDetailsContext, getDaishoUser } from "lib/auth";

import { auth } from "./firebase";
import { callNewUserApiHook } from "./new-user-alerts";

const checkIfNewUser = (user: User) => {
  const now = Date.now().valueOf();
  const created = new Date(user.metadata.creationTime).valueOf();
  const lastLogin = new Date(user.metadata.lastSignInTime).valueOf();

  // Both times are milliseconds.
  // Its a new user if last login and created are within a few seconds (3) of each other
  // And its been no more than 2 minutes since the login was created
  const isNewUser =
    Math.abs(lastLogin - created) <= 1000 * 3 &&
    Math.abs(now - created) <= 1000 * 60 * 2;

  return isNewUser;
};

export const useSendAlerts = () => {
  const location = useGetLocation();
  const [alertSent, setAlertSent] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    if (user) {
      const userIsNew = checkIfNewUser(user);
      if (userIsNew && !alertSent) {
        const provider = user.providerData[0].providerId;
        const currency = location === "India" ? "INR" : "USD";
        callNewUserApiHook(user.email, provider, currency, user.displayName);
      }
      setAlertSent(true);
    }
  }, [user, alertSent, location]);
};

export const useGetFirebaseLoginHandler = () => {
  const { update: updateLoginFlag } = useContext(LoginFlagContext);
  const { update: updateUser } = useContext(UserDetailsContext);

  const location = useGetLocation();

  return useCallback(
    async (user?: User) => {
      user = user || auth.currentUser;
      if (user) {
        try {
          updateLoginFlag({ loggedIn: true, inProgress: true });
          const daishoUser = await getDaishoUser(user);
          updateUser(daishoUser);
          updateLoginFlag({ loggedIn: true, inProgress: false });
        } catch (e) {
          updateLoginFlag({ loggedIn: true, inProgress: false });
        }
      } else {
        updateLoginFlag({ loggedIn: false, inProgress: false });
      }
    },
    [updateLoginFlag, updateUser, location]
  );
};

export default useGetFirebaseLoginHandler;
