import Link from "next/link";

const HeaderLink = (props: {
  href: string;
  label: React.ReactNode;
  subtitle?: string;
  naked?: boolean;
  classes?: string;
}) =>
  props.naked ? (
    <Link href={props.href}>{props.label}</Link>
  ) : (
    <Link href={props.href} passHref>
      <a className="block lg:inline-block hover:bg-gray-300 w-full">
        <p
          className={
            props.classes ||
            `mt-2 lg:mt-0 font-medium text-gray-600 lg:px-5 lg:pt-3 lg:flex lg:items-center transition duration-150 ease-in-out ${
              props.subtitle ? "lg:pt-3" : "lg:py-3"
            }`
          }
          style={{ outline: "none" }}
        >
          {props.label}
        </p>
        {props.subtitle && (
          <p
            className={
              props.classes ||
              "mt-2 lg:mt-0 text-sm text-gray-600 lg:px-5 lg:flex lg:pb-3 lg:items-center transition duration-150 ease-in-out"
            }
          >
            {props.subtitle}
          </p>
        )}
      </a>
    </Link>
  );

export default HeaderLink;
