import { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

import AOS from "aos";

import { DefaultSeo } from "next-seo";

import "aos/dist/aos.css";

import SEO from "../../../next-seo.config";

import { AuthShell } from "lib/auth";
// import { updateCaptcha } from "lib/captcha";
import { LocationProvider, useLocation } from "lib/location";
import { useInitFirebase } from "lib/firebase";

import Header from "./Header";
import Footer from "./Footer";

const Body = ({ children }) => {
  useLocation();
  const router = useRouter();
  const path = router.pathname;

  useEffect(() => {
    AOS.init({ once: true });
    // updateCaptcha(() => {}, path);
  }, []);

  useInitFirebase();

  return (
    <>
      <Head>
        <title>Daisho | Become a Data Science SuperHero</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <DefaultSeo {...SEO} />
      <div className="flex flex-col min-h-screen overflow-hidden" id="root">
        <Header />
        <main className="flex-grow">
          {/*  Page content */}
          {children}
        </main>
        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
};

const AppShell = ({ children }) => {
  return (
    <LocationProvider>
      <AuthShell>
        <Body>{children}</Body>
      </AuthShell>
    </LocationProvider>
  );
};

export default AppShell;
