import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

export const createUserWithEmail = async (email: string, password: string) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);
    return { success: true };
  } catch (error) {
    const { code, message } = error;
    switch (code) {
      case "auth/email-already-in-use":
        return {
          success: false,
          code,
          message: "This email is already in use",
        };
      case "auth/invalid-email":
        return { success: false, code, message: "Invalid Email Address" };
      default:
        return { success: false, code, message };
    }
  }
};

export default createUserWithEmail;
