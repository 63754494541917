import { useEffect } from "react";

import { getApps } from "firebase/app";
import { onAuthStateChanged } from "firebase/auth";

import { auth } from "./firebase";
import useGetFirebaseLoginHandler from "./on-login";

export const useInitFirebase = () => {
  const ready = getApps().length >= 1;
  const handleLogin = useGetFirebaseLoginHandler();

  useEffect(() => {
    if (ready) {
      onAuthStateChanged(auth, handleLogin);
    }
  }, [ready, handleLogin]);

  return;
};
