import { useState, useEffect } from "react";

import Link from "next/link";
import Image from "next/image";

import { useGetLoginStatus, useGetLogout } from "lib/auth";
import DropDown from "components/Dropdown";

import HeaderLink from "./Link";

function Header() {
  const [top, setTop] = useState(true);
  const [hamburgerIsOpen, openHamburger] = useState(false);

  const loggedIn = useGetLoginStatus();
  const logout = useGetLogout();

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full pt-2 z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white blur shadow-lg"
      }`}
    >
      <div className="max-w-screen-xl mx-auto px-5 sm:px-6">
        <div className="flex flex-no-wrap md:flex-no-wrap items-center justify-between h-16 md:h-24">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/" passHref>
              <a className="block" aria-label="Cruip">
                <Image
                  src={"/daisho-purple-logo.png"}
                  alt="Daisho"
                  className="h-16 md:h-20 object-contain"
                  width={200}
                  height={80}
                />
              </a>
            </Link>
          </div>

          {/* Site navigation */}
          {/* Small Screen Navigation: Hamburger */}
          <nav
            className="block lg:hidden"
            onClick={() => openHamburger(!hamburgerIsOpen)}
          >
            <button className="navbar-burger flex items-center py-2 px-3 text-brand-600 rounded border border-brand-600">
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </nav>
          <div
            className={`navbar-menu w-full ${
              hamburgerIsOpen ? "" : "hidden"
            } bg-white text-right pb-2 divide-y divide-gray-200 self-start`}
          >
            <div>
              <HeaderLink href="/philosophy" label="Our Philosophy" />
              <HeaderLink href="/technology" label="Technology" />
              <HeaderLink href="/use-cases" label="Use Cases" />
              {/* <HeaderLink href="/case-studies" label="Case Studies" /> */}
            </div>
            <div>
              <HeaderLink href="/pricing" label="Pricing" />
              <HeaderLink href="/blog" label="Blog" />
            </div>
            <div>
              <HeaderLink href="/about-us" label="Our Story" />
              <HeaderLink href="/faq" label="FAQ" />
              <HeaderLink href="/contact" label="Get in touch" />
            </div>
            {loggedIn ? (
              <>
                <a
                  className="block lg:inline-block hover:bg-gray-300 w-full"
                  onClick={logout}
                >
                  <p
                    className={`mt-2 lg:mt-0 font-medium text-gray-600 lg:px-5 lg:pt-3 lg:flex lg:items-center transition duration-150 ease-in-out lg:py-3`}
                    style={{ outline: "none" }}
                  >
                    Sign Out
                  </p>
                </a>
                <div className="btn-sm text-gray-200 bg-brand-600 hover:bg-brand-900 mt-2 rounded">
                  <a href={process.env.NEXT_PUBLIC_DAISHO_APP_LINK}>
                    <p className="font-medium transition duration-150 ease-in-out">
                      Your Analysis
                    </p>
                  </a>
                </div>
              </>
            ) : (
              <>
                <HeaderLink href="/signin" label="Sign in" />
                <div className="btn-sm text-gray-200 bg-brand-600 hover:bg-brand-900 mt-2 rounded">
                  <Link href={"/signup?action=trial"}>
                    <p className="font-medium transition duration-150 ease-in-out">
                      Try for Free
                    </p>
                  </Link>
                </div>
              </>
            )}
          </div>

          {/* Big Screen Navigation. Hodden for sm and below. */}
          <nav className={`navbar-menu lg:flex lg:flex-grow w-full hidden`}>
            <ul className="lg:mr-auto lg:flex lg:flex-grow lg:justify-start lg:ml-8 lg:flex-wrap items-center">
              <li>
                <DropDown title="Product">
                  <HeaderLink
                    href="/philosophy"
                    label="Our Philosophy"
                    subtitle="Maximizing value from data"
                  />
                  <HeaderLink
                    href="/technology"
                    label="Technology"
                    subtitle="Built from ML Experience and Research"
                  />
                  <HeaderLink
                    href="/vs-auto-ml"
                    label="Daisho vs Auto-ML"
                    subtitle="Build with business users in mind"
                  />
                </DropDown>
              </li>
              <li>
                <HeaderLink href="/use-cases" label="Use Cases" />
              </li>
              <li>
                <HeaderLink href="/pricing" label="Pricing" />
              </li>
              <li>
                <HeaderLink href="/faq" label="FAQ" />
              </li>
              <li>
                <DropDown title="Company">
                  <HeaderLink
                    href="/about-us"
                    label="Our Story"
                    subtitle="Why Daisho exists"
                  />
                  <HeaderLink
                    href="/blog"
                    label="The Daisho.ml Blog"
                    subtitle="Our thoughts, views and updates"
                  />
                  <HeaderLink
                    href="/contact"
                    label="Get in touch"
                    subtitle="Drop us a note."
                  />
                </DropDown>
              </li>
            </ul>
            <ul className="lg:flex lg:flex-grow lg:justify-end lg:flex-wrap items-center">
              {loggedIn ? (
                <>
                  <li>
                    <a
                      className="block lg:inline-block hover:bg-gray-300 w-full"
                      onClick={logout}
                    >
                      <p
                        className={`mt-2 lg:mt-0 font-medium text-gray-600 lg:px-5 lg:pt-3 lg:flex lg:items-center transition duration-150 ease-in-out lg:py-3 cursor-pointer`}
                        style={{ outline: "none" }}
                      >
                        Sign Out
                      </p>
                    </a>
                  </li>
                  <li className="btn-sm text-gray-200 bg-brand-600 hover:bg-brand-900 ml-3 rounded">
                    <a
                      className="block lg:inline-block w-full"
                      href={process.env.NEXT_PUBLIC_DAISHO_APP_LINK}
                    >
                      <p className="font-medium px-5 py-3 flex items-center transition duration-150 ease-in-out">
                        <span>Your Analysis</span>
                        <span>
                          <svg
                            className="w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </span>
                      </p>
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <HeaderLink href="/signin" label="Sign in" />
                  </li>

                  <li className="btn-sm text-gray-200 bg-brand-600 hover:bg-brand-900 ml-3 rounded">
                    <Link href="/signup?action=trial" passHref>
                      <a className="block lg:inline-block w-full">
                        <p className="font-medium px-5 py-3 flex items-center transition duration-150 ease-in-out">
                          <span>Try for Free</span>
                          <span>
                            <svg
                              className="w-3 h-3 fill-current text-gray-200 flex-shrink-0 ml-2 -mr-1"
                              viewBox="0 0 12 12"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </span>
                        </p>
                      </a>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
